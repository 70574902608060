"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Sso_instances, _Sso_admin_token, _Sso_server_token, _Sso_user_id, _Sso_refresh_token, _Sso_expires, _Sso_expires_in_seconds_get;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sso = exports.Auth = void 0;
const zod_1 = require("zod");
const storage_1 = require("../utils/system/storage");
exports.Auth = zod_1.z.object({
    AdminToken: zod_1.z.string(),
    ServerToken: zod_1.z.string(),
    UserId: zod_1.z.string(),
    RefreshToken: zod_1.z.string(),
    Expires: zod_1.z.date(),
});
const EmptyAuth = {
    AdminToken: "",
    ServerToken: "",
    UserId: "",
    RefreshToken: "",
    Expires: new Date(),
};
class Sso {
    constructor(dto) {
        _Sso_instances.add(this);
        _Sso_admin_token.set(this, void 0);
        _Sso_server_token.set(this, void 0);
        _Sso_user_id.set(this, void 0);
        _Sso_refresh_token.set(this, void 0);
        _Sso_expires.set(this, void 0);
        __classPrivateFieldSet(this, _Sso_admin_token, dto.AdminToken, "f");
        __classPrivateFieldSet(this, _Sso_server_token, dto.ServerToken, "f");
        __classPrivateFieldSet(this, _Sso_user_id, dto.UserId, "f");
        __classPrivateFieldSet(this, _Sso_refresh_token, dto.RefreshToken, "f");
        __classPrivateFieldSet(this, _Sso_expires, new Date(dto.Expires), "f");
    }
    get ShouldRefresh() {
        return __classPrivateFieldGet(this, _Sso_instances, "a", _Sso_expires_in_seconds_get) <= 60;
    }
    get IsExpired() {
        return __classPrivateFieldGet(this, _Sso_instances, "a", _Sso_expires_in_seconds_get) <= 0;
    }
    get AdminToken() {
        return __classPrivateFieldGet(this, _Sso_admin_token, "f");
    }
    get ServerToken() {
        return __classPrivateFieldGet(this, _Sso_server_token, "f");
    }
    get RefreshToken() {
        return __classPrivateFieldGet(this, _Sso_refresh_token, "f");
    }
    get UserId() {
        return __classPrivateFieldGet(this, _Sso_user_id, "f");
    }
    static get Stored() {
        var _a;
        return new Sso(exports.Auth.parse((_a = storage_1.Session.auth) !== null && _a !== void 0 ? _a : EmptyAuth));
    }
    static get Empty() {
        return new Sso(exports.Auth.parse(EmptyAuth));
    }
}
exports.Sso = Sso;
_Sso_admin_token = new WeakMap(), _Sso_server_token = new WeakMap(), _Sso_user_id = new WeakMap(), _Sso_refresh_token = new WeakMap(), _Sso_expires = new WeakMap(), _Sso_instances = new WeakSet(), _Sso_expires_in_seconds_get = function _Sso_expires_in_seconds_get() {
    return (__classPrivateFieldGet(this, _Sso_expires, "f").getTime() - new Date().getTime()) / 1000;
};
