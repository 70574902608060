"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemMatch = void 0;
const react_native_1 = require("react-native");
function SystemMatch(options) {
    const match = options[react_native_1.Platform.OS];
    if (!match)
        throw new Error("Unsupported operating system");
    return match();
}
exports.SystemMatch = SystemMatch;
