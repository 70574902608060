"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToBase64 = void 0;
function ToBase64(file) {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result !== "string")
                throw new Error("Could not read file");
            const data_url = reader.result;
            const just_data = data_url.replace(/^data:[a-z0-9A-Z\-]+\/[a-z0-9A-Z\-]+;[a-z0-9A-Z\-]+,/, "");
            res({ base64: just_data, mime: file.type });
        };
        reader.onerror = (error) => {
            rej(error);
        };
        reader.readAsDataURL(file);
    });
}
exports.ToBase64 = ToBase64;
