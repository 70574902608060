"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_1 = require("../styles/theme");
const native_1 = require("./native");
exports.default = (props) => {
    var _a;
    const colour = theme_1.Colours[(_a = props.colour) !== null && _a !== void 0 ? _a : "Primary"];
    return ((0, jsx_runtime_1.jsx)(native_1.Pressable, { class: ["container", props.class], style: { backgroundColor: colour.Background }, onPress: props.on_click, children: (0, jsx_runtime_1.jsx)(native_1.Text, { class: "body_text", style: { textAlign: "center", color: colour.Foreground }, children: props.children }) }));
};
