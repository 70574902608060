"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const args_1 = require("../utils/system/args");
let args_listeners = [];
function UseArgs() {
    const [args, set_args] = (0, react_1.useState)((0, args_1.GetArgs)());
    (0, react_1.useEffect)(() => {
        const handler = () => set_args((0, args_1.GetArgs)());
        args_listeners = [...args_listeners, handler];
        return () => {
            args_listeners = args_listeners.filter((a) => a !== handler);
        };
    }, []);
    return {
        state: args,
        actions: {
            clear_args: () => {
                (0, args_1.ClearArgs)();
                for (const listener of args_listeners)
                    listener();
            },
        },
    };
}
exports.default = UseArgs;
