"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_1 = require("./common");
const native_1 = require("../native");
function Form(props) {
    const [data, set_data] = (0, react_1.useState)({});
    const [callbacks, set_callbacks] = (0, react_1.useState)([]);
    const dataRef = (0, react_1.useRef)();
    dataRef.current = data;
    const on_submit = (0, react_1.useCallback)(() => __awaiter(this, void 0, void 0, function* () {
        for (const callback of callbacks)
            callback();
        const data = dataRef.current;
        props.on_submit(props.form_type.parse(data));
    }), [dataRef, callbacks, props.on_submit]);
    return ((0, jsx_runtime_1.jsx)(common_1.FormContext.Provider, { value: {
            set_value(name, value) {
                set_data((d) => (Object.assign(Object.assign({}, d), { [name]: value })));
                return { type: "ok" };
            },
            get_value(name) {
                return data[name];
            },
            on_submit(handler) {
                set_callbacks((c) => [...c, handler]);
            },
            off_submit(handler) {
                set_callbacks((c) => c.filter((h) => h !== handler));
            },
            submit: on_submit,
        }, children: (0, jsx_runtime_1.jsx)(native_1.View, { class: ["column", props.class], children: props.children }) }));
}
exports.default = Form;
