"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Server_instances, _Server_server_url, _Server_local_token, _Server_expires, _Server_is_admin, _Server_expires_in_seconds_get;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Server = exports.Auth = void 0;
const zod_1 = require("zod");
const fetch_1 = require("../utils/fetch");
const date_fns_1 = require("date-fns");
exports.Auth = zod_1.z.object({
    LocalToken: zod_1.z.string(),
    IsAdmin: zod_1.z.boolean(),
});
class Server {
    constructor(dto, server_url) {
        _Server_instances.add(this);
        _Server_server_url.set(this, void 0);
        _Server_local_token.set(this, void 0);
        _Server_expires.set(this, void 0);
        _Server_is_admin.set(this, void 0);
        __classPrivateFieldSet(this, _Server_server_url, server_url, "f");
        __classPrivateFieldSet(this, _Server_local_token, dto.LocalToken, "f");
        __classPrivateFieldSet(this, _Server_expires, (0, date_fns_1.addHours)(new Date(), 12), "f");
        __classPrivateFieldSet(this, _Server_is_admin, dto.IsAdmin, "f");
    }
    get ShouldRefresh() {
        return __classPrivateFieldGet(this, _Server_instances, "a", _Server_expires_in_seconds_get) <= 60;
    }
    get IsExpired() {
        return __classPrivateFieldGet(this, _Server_instances, "a", _Server_expires_in_seconds_get) <= 0;
    }
    AsRefreshed(sso) {
        return __awaiter(this, void 0, void 0, function* () {
            return Server.ForServer(__classPrivateFieldGet(this, _Server_server_url, "f"), sso);
        });
    }
    get LocalToken() {
        return __classPrivateFieldGet(this, _Server_local_token, "f");
    }
    get IsAdmin() {
        return __classPrivateFieldGet(this, _Server_is_admin, "f");
    }
    get BaseUrl() {
        return __classPrivateFieldGet(this, _Server_server_url, "f");
    }
    static ForServer(url, sso) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield (0, fetch_1.Fetch)("/api/v1/auth/token", {
                method: "GET",
                expect: exports.Auth,
                area: "server",
                base_url: url,
            }, {
                token: sso.ServerToken,
            });
            return new Server(data, url);
        });
    }
}
exports.Server = Server;
_Server_server_url = new WeakMap(), _Server_local_token = new WeakMap(), _Server_expires = new WeakMap(), _Server_is_admin = new WeakMap(), _Server_instances = new WeakSet(), _Server_expires_in_seconds_get = function _Server_expires_in_seconds_get() {
    return (__classPrivateFieldGet(this, _Server_expires, "f").getTime() - new Date().getTime()) / 1000;
};
