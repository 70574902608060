"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClearArgs = exports.GetArgs = void 0;
const utils_1 = require("./utils");
exports.GetArgs = (0, utils_1.SystemMatch)({
    web: () => () => {
        const query = new URLSearchParams(window.location.search);
        const result = {};
        for (const [key, value] of query.entries())
            result[key] = value;
        return result;
    },
});
exports.ClearArgs = (0, utils_1.SystemMatch)({
    web: () => () => {
        history.pushState(undefined, "", window.location.pathname);
    },
});
