"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UseSubmitter = exports.UseForm = exports.FormContext = void 0;
const react_1 = require("react");
exports.FormContext = (0, react_1.createContext)({
    set_value(name, value) {
        return { type: "ok" };
    },
    get_value(name) { },
    on_submit(handler) { },
    off_submit(handler) { },
    submit() { },
});
function UseForm(name) {
    const data = (0, react_1.useContext)(exports.FormContext);
    return {
        value: data.get_value(name),
        set_value: (value) => data.set_value(name, value),
        use_submit: (effect, effectors) => {
            (0, react_1.useEffect)(() => {
                data.on_submit(effect);
                return () => {
                    data.off_submit(effect);
                };
            }, effectors);
        },
        submit: data.submit,
    };
}
exports.UseForm = UseForm;
function UseSubmitter() {
    const data = (0, react_1.useContext)(exports.FormContext);
    return data.submit;
}
exports.UseSubmitter = UseSubmitter;
