"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const native_1 = require("./native");
const icons = {
    user: "\uF264",
    check: "\uEB7B",
    "upload-cloud-2": "\uF24C",
    "loader-2": "\uEEC2",
    close: "\uEB99",
    "arrow-left": "\uEA60",
    "settings-2": "\uF0E4",
    "send-plane-2": "\uF0D8",
    "lock-2": "\uEECC",
    "chat-3": "\uEB51",
};
exports.default = (props) => ((0, jsx_runtime_1.jsx)(native_1.Text, { class: props.class, style: {
        fontSize: props.size,
        fontFamily: "RemixIcon",
    }, children: icons[props.icon] }));
