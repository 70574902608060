"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const form_1 = require("./form");
exports.default = (props) => {
    const { value, set_value } = (0, form_1.UseForm)(props.name);
    (0, react_1.useEffect)(() => {
        set_value(props.value);
    }, [props.value]);
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
};
