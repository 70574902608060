"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInput = exports.Modal = exports.Image = exports.ScrollView = exports.Pressable = exports.Text = exports.View = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const O = __importStar(require("react-native"));
const theme_1 = require("../styles/theme");
const react_1 = require("react");
const View = (props) => ((0, jsx_runtime_1.jsx)(O.View, Object.assign({}, props, { style: Object.assign(Object.assign({}, (0, theme_1.Render)(props.class)), (typeof props.style === "object" ? props.style : {})) })));
exports.View = View;
const Text = (props) => ((0, jsx_runtime_1.jsx)(O.Text, Object.assign({}, props, { style: Object.assign(Object.assign({}, (0, theme_1.Render)(props.class)), (typeof props.style === "object" ? props.style : {})) })));
exports.Text = Text;
const Pressable = (props) => ((0, jsx_runtime_1.jsx)(O.Pressable, Object.assign({}, props, { style: Object.assign(Object.assign({}, (0, theme_1.Render)(props.class)), (typeof props.style === "object" ? props.style : {})) })));
exports.Pressable = Pressable;
const ScrollView = (props) => ((0, jsx_runtime_1.jsx)(O.ScrollView, Object.assign({}, props, { style: Object.assign(Object.assign({}, (0, theme_1.Render)(props.class)), (typeof props.style === "object" ? props.style : {})) })));
exports.ScrollView = ScrollView;
const Image = (props) => ((0, jsx_runtime_1.jsx)(O.Image, Object.assign({}, props, { style: Object.assign(Object.assign({}, (0, theme_1.Render)(props.class)), (typeof props.style === "object" ? props.style : {})) })));
exports.Image = Image;
const Modal = (props) => ((0, jsx_runtime_1.jsx)(O.Modal, Object.assign({}, props, { style: Object.assign(Object.assign({}, (0, theme_1.Render)(props.class)), (typeof props.style === "object" ? props.style : {})) })));
exports.Modal = Modal;
exports.TextInput = (0, react_1.forwardRef)((props, ref) => ((0, jsx_runtime_1.jsx)(O.TextInput, Object.assign({}, props, { ref: ref, style: Object.assign(Object.assign({}, (0, theme_1.Render)(props.class)), (typeof props.style === "object" ? props.style : {})) }))));
