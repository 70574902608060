"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_native_1 = require("react-native");
function GetOrientation() {
    return react_native_1.Dimensions.get("window").width < 600
        ? "portrait"
        : "landscape";
}
function UseOrientation() {
    const [orientation, set_orientation] = (0, react_1.useState)(GetOrientation());
    (0, react_1.useEffect)(() => {
        const listener = () => set_orientation(GetOrientation());
        react_native_1.Dimensions.addEventListener("change", listener);
    });
    return orientation;
}
exports.default = UseOrientation;
