"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const form_1 = require("./form");
const react_native_1 = require("react-native");
const zod_1 = require("zod");
const theme_1 = require("../styles/theme");
const native_1 = require("./native");
const DefaultHeight = 35;
exports.default = (props) => {
    const { value, set_value, submit, use_submit } = (0, form_1.UseForm)(props.name);
    const [focused, set_focused] = (0, react_1.useState)(false);
    const input = (0, react_1.useRef)(null);
    const [height, set_height] = (0, react_1.useState)(DefaultHeight);
    (0, react_1.useEffect)(() => {
        var _a;
        set_value((_a = props.default_value) !== null && _a !== void 0 ? _a : "");
    }, [props.default_value]);
    use_submit(() => {
        if (props.clear_on_submit) {
            set_height(DefaultHeight);
            set_value("");
        }
    }, [props.clear_on_submit]);
    return ((0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { onFocus: () => {
            var _a;
            set_focused(true);
            (_a = input.current) === null || _a === void 0 ? void 0 : _a.focus();
        }, children: (0, jsx_runtime_1.jsxs)(native_1.View, { class: ["card", "colour_body", props.class], children: [(0, jsx_runtime_1.jsx)(native_1.Text, { class: "body_text container colour_body", style: Object.assign({}, (!!(value || focused)
                        ? {
                            position: "absolute",
                            top: -10,
                            left: 4,
                            padding: 2,
                            fontSize: theme_1.FontSizes.Small,
                        }
                        : {
                            position: "absolute",
                            top: 0,
                            left: 3,
                            fontSize: theme_1.FontSizes.Label,
                        })), children: props.children }), (0, jsx_runtime_1.jsx)(native_1.TextInput, { ref: input, class: "body_text edge_container", style: {
                        outlineStyle: "none",
                        height: height,
                    }, value: value ? zod_1.z.string().parse(value) : "", onChangeText: set_value, keyboardType: props.keyboard, secureTextEntry: props.password, onKeyPress: (e) => {
                        if (props.multiline && e.key === "Enter" && e.shiftKey) {
                            e.preventDefault();
                            submit();
                        }
                        else if (e.key === "Enter" && !props.multiline) {
                            submit();
                        }
                    }, onFocus: () => set_focused(true), onBlur: () => set_focused(false), multiline: props.multiline, onContentSizeChange: (e) => set_height(e.nativeEvent.contentSize.height) })] }) }));
};
